import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Video from "../images/video.mp4";

const About = () => {
  return (
    <div>
      <div id="about">
        <div className="">
          <div className="max-w-md lg:max-w-7xl mx-auto flex flex-col gap-8 items-center justify-center text-center">
            <p className="hightlight font-bold text-primary">
              UN PARAÍSO DE LA COSTA MICHOACANA
            </p>
            <p className="title text-center">
              Todo lo que necesitas para una buena aventura, lo encuentras en
              Nexpa
            </p>
            <Link to="/card">
              <button className="button-secondary">
                Conoce nuestras Cabañas
              </button>
            </Link>

            <p className="paragraph pt-4">
              Nexpa es un paraíso para los surfistas, que se acercan de todas
              partes del mundo en busca de sus olas todos los meses del año.
              Pero además, por las características del oleaje, en las aguas de
              Nexpa puedes practicar esquí acuático, buceo y natación.
            </p>

            <p className="paragraph">
              Sus extensas playas, rodeadas por cocoteros, son un tesoro de la
              costa Michoacana. Aquí puedes disfrutar no solo del deporte, sino
              también de la paz y tranquilidad del lugar.
            </p>

            <p className="paragraph">
              En esta zona desemboca el Río Nexpa, en donde puedes dar paseos en
              kayak, así como también pescar en sus orillas.
            </p>

            <p className="paragraph">
              Nexpa es un sitio que lo tiene todo. <br />
              Te esperamos!
            </p>
          </div>
        </div>

        <div className="">
          <p className="highlight text-center mt-16 pb-4">
            Vive la experiencia #Nexpa
          </p>
          <div className="overflow-x-scroll snap-x lg:snap-none flex gap-4 py-4 mb-4 lg:grid lg:grid-cols-5 lg:gap-8 place-items-center lg:px-4 lg:mx-auto">
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/9.jpeg"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/1.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>

            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/2.jpg"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block w-full"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/3.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/4.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/5.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/6.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/7.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0 pr-4 lg:pr-0">
              <StaticImage
                width={268}
                height={380}
                src="../images/Experiencia/8.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0 ">
              <video
                src={Video}
                muted
                autoPlay
                loop
                type="video/mp4"
                className=" flex-shrink-1 h-96 w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
